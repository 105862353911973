<template>
  <div>
    <b-sidebar id="client-form" right shadow>
      <template #title>
        <p style="margin: 1rem">
          {{ sidebarAction + " Client Details" }}
        </p>
      </template>
      <div class="px-3 py-2">
        <validation-observer ref="clientFormRules">
          <b-form @submit.prevent>
            <b-row>
              <!-- First Name -->
              <b-col cols="12">
                <b-form-group label="First Name" label-for="client-first-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Client First Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.client_firstname"
                      id="client-first-name"
                      placeholder="First Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Last Name -->
              <b-col cols="12">
                <b-form-group label="Last Name" label-for="client-last-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Client Last Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.client_lastname"
                      id="client-last-name"
                      placeholder="Last Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Email -->
              <b-col cols="12">
                <b-form-group label="Email" label-for="client-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Client Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="form.client_email"
                      id="client-email"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Mobile -->
              <b-col cols="12">
                <b-form-group label="Mobile" label-for="client-mobile">
                  <validation-provider
                    #default="{ errors }"
                    name="Client Number"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.client_mobile"
                      id="client-mobile"
                      type="number"
                      placeholder="Mobile"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Header Image -->
              <!-- <b-col cols="12">
                <b-form-group label="Heder Image" label-for="client-header-img">
                  <b-form-file
                    v-model="form.client_header_img_file"
                    @input="show_img($event, 'client_header_img')"
                    id="client-header-img"
                    accept="image/jpeg, image/png, image/gif"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-img
                  v-if="form.client_header_img"
                  :src="form.client_header_img"
                  fluid
                />
              </b-col> -->

              <!-- Footer Image -->
              <!-- <b-col cols="12">
                <b-form-group
                  label="Footer Image"
                  label-for="client-footer-img"
                >
                  <b-form-file
                    v-model="form.client_footer_img_file"
                    @input="show_img($event, 'client_footer_img')"
                    id="client-footer-img"
                    accept="image/jpeg, image/png, image/gif"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-img
                  v-if="form.client_footer_img"
                  :src="form.client_footer_img"
                  fluid
                />
              </b-col> -->

              <!-- Is Active -->
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox
                    v-model="form.client_is_active"
                    :checked="form.client_is_active"
                    name="check-button"
                    id="client-active"
                    switch
                    inline
                  >
                    Client {{ form.client_is_active ? "Active" : "InActive" }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- submit -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="SaveClient"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-card>
      <div class="btn1 mb-1">
        <div class="search">
          <b-input-group class="input-merge mt-">
            <b-form-input placeholder="Search" v-model="search" />
          </b-input-group>
          <b-button @click="addClientAction" class="mx-2 px-3">
            Add&nbsp;Client
          </b-button>
        </div>
      </div>

      <b-table
        small
        :fields="fields"
        :items="client_list"
        responsive="sm"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
      >
        <template #cell(client_name)="data">
          {{ data.item.client_firstname + " " + data.item.client_lastname }}
          <!-- {{ data }} -->
        </template>

        <template #cell(client_is_active)="data">
          <b-badge
            pill
            :variant="data.item.client_is_active ? 'success' : 'danger'"
          >
            {{ data.item.client_is_active ? "Active" : "InActive" }}
          </b-badge>
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
        <template #cell(edit)="data">
          <div class="h2 mb-0">
            <feather-icon
              class="edit"
              icon="EditIcon"
              @click="editClientAction(data.item)"
            />
          </div>
        </template>
      </b-table>
      <div class="demo-spacing-0">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="client_list.length"
          :per-page="pagination.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BSidebar,
  VBToggle,
  BPagination,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { getAllClientList, saveClient } from "./useClient";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    required,
    email,
    BCard,
    BPagination,
    BSpinner,
    BRow,
    BCardText,
    BLink,
    BTable,
    BProgress,
    BBadge,
    BTable,
    BButton,
    FeatherIcon,
    BSidebar,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },

  data() {
    return {
      search: "",
      required,
      email,
      form: {
        client_id: null,
        client_firstname: null,
        client_lastname: null,
        client_email: null,
        client_mobile: null,
        // client_header_img_file: null,
        // client_header_img: null,
        // client_footer_img_file: null,
        // client_footer_img: null,
        client_is_active: true,
      },

      sidebarAction: "Add",
      fields: [
        { key: "client_name", label: "Name" },
        { key: "client_email", label: "Email" },
        { key: "client_mobile", label: "Mobile" },
        { key: "client_is_active", label: "Status" },
        { key: "edit", label: "Action" },
      ],
      all_client: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
      },
    };
  },

  computed: {
    client_list: function () {
      let arr = this.all_client;
      let search = this.search;
      if (search) {
        search = search.toLowerCase();
        arr = arr.filter((item, index) => {
          let username = item.client_firstname + item.client_lastname;
          username = username.toLowerCase();
          if (
            (username && username.includes(search)) ||
            (item.client_email && item.client_email.toLowerCase().includes(search))
          ) {
            return true;
          }
          return false;
        });
      }
      return arr;
    },
  },

  methods: {
    // show_img(file, img_key) {
    //   if (this.form[img_key]) return;
    //   var reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = (e) => {
    //     this.form[img_key] = e.target.result;
    //   };
    // },
    validationForm() {
      this.$refs.emailvalidationref.validate().then((success) => {
        if (success) {
          alert("form submitted!");
        }
      });
    },
    addClientAction() {
      this.toggleSidebar();
      this.sidebarAction = "Add";
    },
    editClientAction(form_data) {
      this.toggleSidebar();
      this.sidebarAction = "Edit";
      this.form = {
        ...this.form,
        ...form_data,
      };
    },
    async SaveClient() {
      this.$refs["clientFormRules"].validate().then(async (success) => {
        if (success) {
          const saved = await saveClient(this.form);
          if (saved) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Client Saved",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.toggleSidebar();
            this.all_client = await getAllClientList();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Client Not Saved",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", "client-form");
      this.form = {
        client_id: null,
        client_firstname: null,
        client_lastname: null,
        client_email: null,
        client_mobile: null,
        client_header_img_file: null,
        client_header_img: null,
        client_footer_img_file: null,
        client_footer_img: null,
        client_is_active: true,
      };
    },
  },
  async beforeMount() {
    this.all_client = await getAllClientList();
  },
};
</script>
<style>
.btn1 {
  text-align: end;
}
.search {
  display: flex;
  width: 70%;
  margin-left: auto;
}
.edit {
  cursor: pointer;
}
#client-form {
  width: 30%;
}
</style>